import React from "react"

const PageBanner = ({
  pageTitle,
  pageTitleText,
  pageSubTitleText,
  bgImageClass,
}) => {
  return (
    <div className={"page-title-area " + bgImageClass}>
      <div className="container">
        <div className="page-title-content">
          <h2>{pageTitle}</h2>
          <hr />
          <p>{pageTitleText}</p>
          <span>{pageSubTitleText}</span>
        </div>
      </div>
    </div>
  )
}

export default PageBanner
