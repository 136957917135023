import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import ourPizzeras from "../assets/images/our-pizzerias-bg.jpg"

const Pizzerias = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Our Pizzerias"
        pageTitleText="EXPECT THE FULL NEW YORK PIZZERIA EXPERIENCE AT
SAL’S. TAKE A SEAT IN A COZY BOOTH AND SOAK UP THE
AROMA COMING FROM OUR HOT OVENS, AMONGST THE
HUSTLE AND BUSTLE OF YOUR PIZZA BEING MADE FRESH,
BY HAND, RIGHT IN FRONT OF YOU."
        bgImageClass="our-pizzerias-bg"
      />
      <section>
        <div className="container">
          <div className="row">
            <div className="col"></div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default Pizzerias
